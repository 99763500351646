<template>
  <div>
    <el-card shadow="never" id="my-product" :body-style="{
      minHeight: 'calc(100vh - 153px)'
    }">
      <el-button type="primary" @click="toPublishProduct">
        新发布
      </el-button>
      <div class="publishing">
        发布中
      </div>
      <div
        v-for="(item, index) in productInfo"
        :key="item.id"
        class="mb-32"
      >
        <el-row
          :gutter="16"
        >
          <el-col :span="3">
            <div class="image-container">
              <img :src="item.productLogo || require('@/assets/kyyl-nt.png')" alt>
            </div>
          </el-col>
          <el-col :span="8">
            <div
              @click="toDetailPage(item.id)"
            >
              <el-card
                class="box-card"
                shadow="never"
                style="cursor: pointer"
                :body-style="{
                ...cardBodyStyle,
                background: index % 2 !== 0 ? '#9CC9F7' : '#DBF7E4'
              }"
              >
                <div class="title">
                  {{ item.productName }}
                </div>
                <div>
                <span class="title red-color">
                  {{ item.slogan || '开源-共享-合作' }}
                </span>
                  <div>{{ '产品：' + item.moduleName + '-' + item.prodType }}</div>
                </div>
                <div class="mb-4">
                  发布于{{ item.createTime }}
                </div>
                <div class="footer">
                  <el-tooltip
                    :content="item.environmentList"
                    placement="top"
                  >
                    <div class="footer-apply grey-color">{{ '适用于:' + item.environmentList }}</div>
                  </el-tooltip>
                  <div class="confirm blue-color">
                    <img src="@/assets/accountCenter/redKey.png" alt/>
                    {{ item.isChain ? '产品已上链确权' : '产品未上链确权' }}
                  </div>
                </div>
              </el-card>
            </div>

          </el-col>
          <el-col :span="12">
            <div @click="toDetailPage(item.id)">
              <el-card
                class="box-card"
                shadow="never"
                style="cursor: pointer"
                :body-style="{
                ...cardBodyStyle,
                background: index % 2 !== 0 ? '#9CC9F7' : '#DBF7E4'
              }"
              >
                <div class="description">
                <div v-for="(i,index) in item.productIntroduction.split('\n')" :key="index">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ i }}
                </div>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="1">
            <i class="el-icon-edit icon" @click="toEditProduct(item.id)"></i>
          </el-col>
        </el-row>
      </div>
      <el-empty v-if="productInfo.length === 0" />
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handlePageChange"
        :page-size="pagination.pageSize"
        :current-page="pagination.pageNum"
        :hide-on-single-page="true"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {Button, Card, Col, Pagination, Row, Tooltip, Loading, Empty} from "element-ui"
import {myProductList} from "@/api/accountCenter";

export default {
  components: {
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Card.name]: Card,
    [Tooltip.name]: Tooltip,
    [Pagination.name]: Pagination,
    [Empty.name]: Empty,
  },
  data() {
    return {
      cardBodyStyle: {
        borderRadius: '8px',
        padding: '12px',
        height: '100px',
      },
      pagination: {
        pageSize: 10,
        pageNum: 1
      },
      total: 0,
      load: undefined,
      productInfo: []
    }
  },
  mounted() {
    this.getProductList()
  },
  methods: {
    getProductList(){
      this.load = Loading.service({
        target: document.getElementById('my-product'),
        text: '加载中...'
      })
      myProductList(this.pagination).then(res => {
        this.load.close()
        this.productInfo = res.data.list
        this.total = res.data.total
      }).catch(() => {
        this.load.close()
      })
    },
    toDetailPage(id){
      this.$router.push({
        name: 'detail',
        params: {
          id: id
        }
      })
    },
    toPublishProduct() {
      this.$router.push({
        name: 'MyProductPublish',
        params: {
          id: 'create'
        }
      })
    },
    toEditProduct(id) {
      this.$router.push({
        name: 'MyProductPublish',
        params: {
          id: id,
        }
      })
    },
    handlePageChange(currentPage) {
      this.pagination.pageNum = currentPage
      this.getProductList()
    },
  }
}

</script>

<style lang="scss" scoped>
.publishing {
  font-weight: 600;
  font-size: 18px;
  margin: 32px 0 16px;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 124px;

  img {
    width: 100px;
    height: 100px;
  }
}

.icon {
  cursor: pointer;
  color: #2299F5;
  width: 20px;
  height: 20px;
}

.title {
  font-size: 18px;
  color: rgba(16, 16, 16, 100);
  margin-bottom: 4px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}

.red-color {
  color: #F5313D;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  margin-top: 4px;

  img {
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }

  span {
    color: #9ca19f;
  }

  .blue-color {
    color: #4850FF;
  }

  .grey-color {
    color: #727070;
  }


  .footer-apply {
    line-height: 16px;
    font-size: 13px;
    text-overflow: ellipsis; /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/
    white-space: nowrap; /* nowrap:规定段落中的文本不进行换行   */
    overflow: hidden; /*超出部分隐藏*/
  }

  .confirm {
    display: flex;
    height: 16px;
    align-items: center;
    font-size: 13px;
  }

  .mb-4 {
    margin-bottom: 4px;
  }
}

.description {
  line-height: 20px;
  height: 100px;
  display: -webkit-box; /*  display: -webkit-box; 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示  */
  -webkit-box-orient: vertical; /*  -webkit-box-orient 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式  */
  -webkit-line-clamp: 5; /*  -webkit-line-clamp用来限制在一个块元素显示的文本的行数 */
  overflow: hidden;
}

.apply-list {
  height: 28px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #9ca19f;
  text-overflow: ellipsis; /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/
  white-space: nowrap; /* nowrap:规定段落中的文本不进行换行   */
  overflow: hidden; /*超出部分隐藏*/
  .blue-color {
    color: #4850FF;
  }
}

.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-32 {
  margin-bottom: 32px;
}

/deep/ .el-card {
  border-radius: 8px;
}

/deep/ .el-button + .el-button {
  margin-left: 4px;
}
</style>
