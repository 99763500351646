var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-card',{attrs:{"shadow":"never","id":"my-product","body-style":{
    minHeight: 'calc(100vh - 153px)'
  }}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.toPublishProduct}},[_vm._v(" 新发布 ")]),_c('div',{staticClass:"publishing"},[_vm._v(" 发布中 ")]),_vm._l((_vm.productInfo),function(item,index){return _c('div',{key:item.id,staticClass:"mb-32"},[_c('el-row',{attrs:{"gutter":16}},[_c('el-col',{attrs:{"span":3}},[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":item.productLogo || require('@/assets/kyyl-nt.png'),"alt":""}})])]),_c('el-col',{attrs:{"span":8}},[_c('div',{on:{"click":function($event){return _vm.toDetailPage(item.id)}}},[_c('el-card',{staticClass:"box-card",staticStyle:{"cursor":"pointer"},attrs:{"shadow":"never","body-style":{
              ..._vm.cardBodyStyle,
              background: index % 2 !== 0 ? '#9CC9F7' : '#DBF7E4'
            }}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.productName)+" ")]),_c('div',[_c('span',{staticClass:"title red-color"},[_vm._v(" "+_vm._s(item.slogan || '开源-共享-合作')+" ")]),_c('div',[_vm._v(_vm._s('产品：' + item.moduleName + '-' + item.prodType))])]),_c('div',{staticClass:"mb-4"},[_vm._v(" 发布于"+_vm._s(item.createTime)+" ")]),_c('div',{staticClass:"footer"},[_c('el-tooltip',{attrs:{"content":item.environmentList,"placement":"top"}},[_c('div',{staticClass:"footer-apply grey-color"},[_vm._v(_vm._s('适用于:' + item.environmentList))])]),_c('div',{staticClass:"confirm blue-color"},[_c('img',{attrs:{"src":require("@/assets/accountCenter/redKey.png"),"alt":""}}),_vm._v(" "+_vm._s(item.isChain ? '产品已上链确权' : '产品未上链确权')+" ")])],1)])],1)]),_c('el-col',{attrs:{"span":12}},[_c('div',{on:{"click":function($event){return _vm.toDetailPage(item.id)}}},[_c('el-card',{staticClass:"box-card",staticStyle:{"cursor":"pointer"},attrs:{"shadow":"never","body-style":{
              ..._vm.cardBodyStyle,
              background: index % 2 !== 0 ? '#9CC9F7' : '#DBF7E4'
            }}},[_c('div',{staticClass:"description"},_vm._l((item.productIntroduction.split('\n')),function(i,index){return _c('div',{key:index},[_vm._v("         "+_vm._s(i)+" ")])}),0)])],1)]),_c('el-col',{attrs:{"span":1}},[_c('i',{staticClass:"el-icon-edit icon",on:{"click":function($event){return _vm.toEditProduct(item.id)}}})])],1)],1)}),(_vm.productInfo.length === 0)?_c('el-empty'):_vm._e(),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.pagination.pageSize,"current-page":_vm.pagination.pageNum,"hide-on-single-page":true,"total":_vm.total},on:{"current-change":_vm.handlePageChange}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }